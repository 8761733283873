.modal {
  transition: opacity 0.25s ease;
}

body.modal-active {
  overflow-x: hidden;
  overflow-y: visible !important;
}

.profile-dd--hidden .profile-dd__inner__menu {
  display: none;
}

.profile-dd--shown .profile-dd__inner {
  @apply border-primary-700;
}

.profile-dd--shown .profile-dd__inner__menu {
  display: block;
}

.profile-dd--shown .bi-chevron-down {
  transform: rotate(180deg);
}

@media (prefers-color-scheme: dark) {
  .profile-dd--shown .profile-dd__inner {
    @apply bg-gray-800;
  }
}

.modal {
  transition: opacity 0.25s ease;
}
body.modal-active {
  overflow-x: hidden;
  overflow-y: visible !important;
}

.post-cover-image img {
  object-fit: contain;
}

.blog-post-item:hover h2 {
  @apply text-primary-500;
}

.post-cover-image.banner-img {
  view-transition-name: banner-img;
}

.dark .prose :where(pre):not(:where([class~='not-prose'] *)) {
  background-color: #262626 !important;
}

/* Spinner css */

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-width: 8px;
  border-style: solid;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
}

@keyframes fade-out {
  to {
    opacity: 0;
  }
}

@keyframes slide-from-right {
  from {
    transform: translateX(30px);
  }
}

@keyframes slide-to-left {
  to {
    transform: translateX(-30px);
  }
}

@keyframes slide-to-right {
  to {
    transform: translateX(30px);
  }
}

@keyframes slide-from-left {
  from {
    transform: translateX(-30px);
  }
}

::view-transition-old(root),
::view-transition-new(root) {
  animation-delay: 3s;
  animation-duration: 5s;
}

::view-transition-old(root) {
  animation: 90ms cubic-bezier(0.4, 0, 1, 1) both fade-out,
    300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-to-left;
}

::view-transition-new(root) {
  animation: 210ms cubic-bezier(0, 0, 0.2, 1) 90ms both fade-in,
    300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-from-right;
}

.back-transition::view-transition-old(root) {
  animation-name: fade-out, slide-to-right;
}

.back-transition::view-transition-new(root) {
  animation-name: fade-in, slide-from-left;
}

.slide-in-left {
  animation: 0.3s ease-in 2s slide-in-left both;
}

@keyframes slide-in-left {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
